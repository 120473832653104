import AckVerify from '../AckVerify';
import ActionLink from '../buttons/ActionLink';
import ContainerDetails from '../container/ContainerDetails';
import DescriptionItem from '../DescriptionItem';
import Dropzone from 'react-dropzone';
import InvisibleButton from '../buttons/InvisibleButton';
import PdfPreviewWithHooks from './PdfPreviewWithHooks';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import SignatureTag from './SignatureTag';
import theme from '../../theme';
import {Alert, Button, Col, Collapse, Divider, Drawer, Dropdown, Icon, Menu, notification, Row, Spin, Table, Tag, Tooltip,} from 'antd';
import {connect} from 'react-redux';
import * as containerActions from '../../actions/container';
import {createArchiveSignature, isContainerInSigningWorkflow, resetSelectedSignature, updateContainerData, updateSelectedSignature, validateSignature} from '../../actions/container';
import {disableArchiveButton, enableArchiveButton} from '../../actions/enable';
import {
    createNotification,
    createNotificationShort,
    formatBytes,
    formatDate,
    getApiUrl,
    getExtension,
    getSchema,
    iconTypeFromType,
    isCegeljaras,
    isCounterSignature,
    isTimestamp,
    removeFilenameCounterPostfix,
} from '../../helper';
import {checkCompanyRegistrationDocument, downloadCommentDocument, downloadDocument, uploadCompanyRegistrationDocument, uploadDocument} from '../../actions/document';
import {isEmpty} from 'lodash';
import {getTranslate} from 'react-localize-redux';
import {toggleModal} from '../../actions/modal';
import '../../css/style.css';
import {ATTRIBUTE_CERTIFICATES, AUTHENTICATION, PDF_PREVIEW_DEPRECATED, REMOVE_DOCUMENT, REMOVE_SIGNATURE, RENAME_DOCUMENT, TSA, UPLOAD_CEGELJARAS} from '../../constants/modalNames';
import DeleteButton from '../buttons/DeleteButton';
import {TAB_KEY_DEFAULT_CONTAINERS} from '../../constants/strings';
import {shorten} from '../../utilities/stringUtilities';
import authService from "../../services/authService";
import SignHidden from "../SignHidden";

const Notification = ({ status, message }) => {
    const type = status ? "success" : "error"
    const alertMessage = status ? message.success : message.error
    switch (status) {
        case true:
        case false:
            return (
                <div>
                    <Alert
                        message={alertMessage}
                        type={type}
                        showIcon
                    />
                </div>
            );
        default:
            return (
                <div style={{ textAlign: 'center', height: 39 }}>
                    <Spin
                        indicator={
                            <Icon
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            );
    }
};

Notification.propTypes = {
    message: PropTypes.object,
    status: PropTypes.bool,
};

export class DocumentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documentSignatures: [],
            files: null,
            previewCounter: 0,
            signatureDetailsPanelVisible: false,
            signatureParentDocumentId: null,
            signatureToArchiveAfterTSAReqChangedToFalse: null,
            signatureVerified: undefined,
            tableLoading: props.tableLoading
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.tsaReq !== prevProps.tsaReq && this.props.tsaReq === false) {
            if (this.state.signatureToArchiveAfterTSAReqChangedToFalse !== null) {
                this.archive(this.state.signatureToArchiveAfterTSAReqChangedToFalse);
                this._isMounted && this.setState({ signatureToArchiveAfterTSAReqChangedToFalse: null });
            }
        }
    }

    onCloseSignatureDetails = () => {
        this._isMounted && this.setState({ signatureDetailsPanelVisible: false, signatureParentDocumentId: null });
        this.updateSelectedSignatureData(null);
    };

    onOpenSignatureDetails = (signature) => {
        const { container } = this.props;
        this.updateSelectedSignatureData(signature);
        this._isMounted && this.setState({ signatureDetailsPanelVisible: true });
        !signature.verified && container.type !== 'eacknowledgement' && this.validateSignature(container.id, signature);
    };

    updateSelectedSignatureData = (signature) => {
        this._isMounted && signature ? this.props.updateSelectedSignatureAction(signature) : this.props.resetSelectedSignatureAction();
    };

    generateType(signatureData) {
        const { translate } = this.props;
        if (isTimestamp(signatureData)) {
            return translate('signatures.timestamp');
        } else {
            let ret =
                translate('signatures.' + signatureData.qualifiedType) +
                ', ' +
                translate('signatures.ades' + signatureData.signatureLevel.replace('-', '').toUpperCase());
            if (isCounterSignature(signatureData)) {
                ret += ' (' + translate('signatures.countersignature') + ')';
            }
            return ret;
        }
    }

    storeDocumentListSignatures(documentId, response) {
        let docSignatures = [];
        docSignatures[documentId] = response.data;
        this._isMounted && this.setState({ documentSignatures: [...this.state.documentSignatures, docSignatures] });
    }

    updateDocumentSignatures(documentId, response) {
        let documentSignatures = Object.assign([], this.state.documentSignatures);
        documentSignatures.forEach((document, index) => {
            if (document[documentId]) {
                documentSignatures[index][documentId] = response.data;
                this._isMounted && this.setState({ documentSignatures });
            }
        });
    }

    archive(signature) {
        const { tsaReq, translate, createArchiveSignatureAction, updateContainerDataAction, container, currentTab } = this.props;
        ReactGA.event({
            category: 'User',
            action: 'Archive Signature',
        });

        if (window.config.REACT_APP_TIMESTAMP_LOGIN_MODE !== 'microsec' && typeof tsaReq === 'undefined') {
            createNotification(this.props.translate, undefined, {
                message: this.props.translate('notifications.getTsaData.failure'),
                type: 'error',
            });

            return;
        }

        if (tsaReq) {
            this._isMounted && this.setState({ signatureToArchiveAfterTSAReqChangedToFalse: signature });
            this.props.toggleTSA();
            return;
        }

        createNotificationShort(this.props.translate, {
            message: translate('notifications.timestampBegin.message'),
            type: 'info',
        });

        createArchiveSignatureAction(container.id, signature.id)
            .then(() => {
                notification.destroy();
                createNotificationShort(this.props.translate, {
                    message: translate('notifications.timestampSuccess.message'),
                    type: 'success',
                });
                this._isMounted && this.setState({ documentSignatures: [] });
                updateContainerDataAction(container.id, currentTab === TAB_KEY_DEFAULT_CONTAINERS);
                this.props.enableArchiveButtonAction();
            })
            .catch((e) => {
                notification.destroy();
                createNotification(this.props.translate, e.response.data.code, {
                    message: translate('notifications.timestampFailure.message'),
                    type: 'error',
                });
            });
    }

    documentContainerHasSignature() {
        const { containerSignatures } = this.props;
        return containerSignatures.length > 0;
    }

    isDocumentSignature(signature) {
        const { containerSignatures } = this.props;
        let retValue = true;
        containerSignatures.forEach((containerSignature) => {
            if (containerSignature.id === signature.id) {
                retValue = false;
                return false;
            }
        });
        return retValue;
    }

    isSignatureArchivable = (signature) => {
        return !(this.documentContainerHasSignature() && this.isDocumentSignature(signature));
    };

    validateSignature(containerId, signature) {
        this._isMounted && this.setState({ signatureVerified: undefined });
        this.props
            .validateSignatureAction(containerId, signature)
            .then(() => {
                this._isMounted && this.setState({ signatureVerified: true });
                this.props.enableArchiveButtonAction();
            })
            .catch(() => {
                this._isMounted && this.setState({ signatureVerified: false });
            });
    }

    handleAttributeCertificatesModal = (e) => {
        e.preventDefault();
        this.props.openAttributeCertificatesModal(this.props.selectedSignature.eSzignoAttributeCertificates);
    };

    toSigCounterSigList = (container, containerSignatures) => {
        let sigCounterSigList = [];
        let nextList = [];
        let lastSigType = '';
        containerSignatures.forEach((signature) => {
            if (lastSigType === '') {
                lastSigType = signature.type;
            } else if (
                container.type !== 'pdf' &&
                signature.type !== lastSigType &&
                !(lastSigType === 'signature' && signature.type === 'timestamp')
            ) {
                sigCounterSigList.push(nextList);
                nextList = [];
                lastSigType = signature.type;
            }
            nextList.push(signature);
        });
        if (nextList.length > 0) {
            sigCounterSigList.push(nextList);
        }
        return sigCounterSigList;
    };

    translateRevocationInfo = (revInfo) => {
        let upperCaseRevInfo = revInfo.toUpperCase();
        if (upperCaseRevInfo.includes('OCSP')) {
            if (upperCaseRevInfo.includes('CRL')) {
                return this.props.translate('signatures.revInfoOCSPAndCRL');
            } else {
                return this.props.translate('signatures.revInfoOCSP');
            }
        } else if (upperCaseRevInfo.includes('CRL')) {
            return this.props.translate('signatures.revInfoCRL');
        } else {
            return this.props.translate('signatures.revInfoNone');
        }
    };

    getDropDownList = (keyname, title, content, data) => {
        const columns = [
            {
                key: 'key',
                dataIndex: 'key',
            },
            {
                key: 'value',
                dataIndex: 'value',
            },
        ];

        return (
            <Collapse
                bordered={false}
                expandIconPosition="right"
                className="active">
                <Collapse.Panel
                    key={keyname}
                    header={
                        <DescriptionItem
                            title={title}
                            content={content}
                            margin="0"
                        />
                    }>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        showHeader={false}
                        size={'small'}
                    />
                </Collapse.Panel>
            </Collapse>
        );
    };

    header = () => {
        const {
            translate,
            container,
            containerSignatures,
            archiveButton,
            setTableLoadingFalse,
            setTableLoadingTrue,
            updateContainers,
            selectedSignature,
            updateContainerDataAction,
            currentTab
        } = this.props;
        const { signatureVerified } = this.state;

        const sigCounterSigList = this.toSigCounterSigList(container, containerSignatures);
        const selectedSignatureIsEmpty = !isEmpty(selectedSignature)

        const signerDNData = selectedSignatureIsEmpty
            ? Object.keys(selectedSignature.subjectDN).map((key) => ({
                key: key,
                value: selectedSignature.subjectDN[key]
            }))
            : [];
        selectedSignatureIsEmpty && signerDNData.push({
            'key': translate('signatures.notBefore'),
            'value': formatDate(selectedSignature.notBefore, translate('language'))
        });
        selectedSignatureIsEmpty && signerDNData.push({
            'key': translate('signatures.notAfter'),
            'value': formatDate(selectedSignature.notAfter, translate('language'))
        });

        const issuerDNData =
            selectedSignatureIsEmpty && selectedSignature.issuerDN
                ? Object.keys(selectedSignature.issuerDN).map((key) => ({
                      key: key,
                      value: selectedSignature.issuerDN[key]
                  }))
                : [];
        const sigTsData =
            selectedSignatureIsEmpty && selectedSignature.signatureTimestamp
                ? [
                      {
                          key: translate('signatures.timestampData.issuerName'),
                          value: selectedSignature.signatureTimestamp.issuerName
                      },
                      {
                          key: translate('signatures.timestampData.issuerValidTo'),
                          value: formatDate(selectedSignature.signatureTimestamp.issuerValidTo, translate('language'))
                      },
                      {
                          key: translate('signatures.timestampData.signatureAlgorithm'),
                          value: selectedSignature.signatureTimestamp.signatureAlgorithm
                      },
                      {
                          key: translate('signatures.timestampData.signatureKeyLength'),
                          value: selectedSignature.signatureTimestamp.signatureKeyLength
                      }
                  ]
                : [];

        const lastArchTsData =
            selectedSignatureIsEmpty && selectedSignature.lastArchiveTimestamp
                ? [
                      {
                          key: translate('signatures.timestampData.issuerName'),
                          value: selectedSignature.lastArchiveTimestamp.issuerName
                      },
                      {
                          key: translate('signatures.timestampData.issuerValidTo'),
                          value: formatDate(selectedSignature.lastArchiveTimestamp.issuerValidTo, translate('language'))
                      },
                      {
                          key: translate('signatures.timestampData.signatureAlgorithm'),
                          value: selectedSignature.lastArchiveTimestamp.signatureAlgorithm
                      },
                      {
                          key: translate('signatures.timestampData.signatureKeyLength'),
                          value: selectedSignature.lastArchiveTimestamp.signatureKeyLength
                      }
                  ]
                : [];

        return (
            <div>
                <Drawer
                    title={translate('drawer.title')}
                    placement="right"
                    closable={true}
                    onClose={this.onCloseSignatureDetails}
                    visible={this.state.signatureDetailsPanelVisible}
                    width={600}
                    zIndex={10}
                    bodyStyle={{ padding: '24px 24px 0px 24px' }}>
                    {selectedSignatureIsEmpty && (
                        <div>
                            <Row>
                                <Col span={24}>
                                    <DescriptionItem
                                        title={
                                            container.type !== 'eacknowledgement' ? (
                                                <span>
                                                    {translate('notifications.validateText')}
                                                    <Button
                                                        id={'validateButton'}
                                                        style={{ float: 'right' }}
                                                        disabled={!archiveButton}
                                                        size={'small'}
                                                        type={'primary'}
                                                        title={translate('buttons.validate.text')}
                                                        onClick={() => {
                                                            this.props.disableArchiveButtonAction();
                                                            this.validateSignature(container.id, selectedSignature);
                                                        }}
                                                        icon="question"
                                                    />
                                                </span>
                                            ) : (
                                                <AckVerify isDrawer={true} />
                                            )
                                        }
                                        content={(() => {
                                            return container.type !== 'eacknowledgement' ? (
                                                <Notification
                                                    message={{
                                                        success: translate('notifications.validate.success'),
                                                        error: translate('notifications.validate.failure'),
                                                    }}
                                                    status={signatureVerified}
                                                />
                                            ) : (
                                                <span></span>
                                            );
                                        })()}
                                    />
                                </Col>
                                <Divider />
                                {selectedSignature.commentDocumentTitle && (
                                    <span>
                                        <Col span={24}>
                                            <DescriptionItem
                                                title={
                                                    <span>
                                                        {translate('signatures.commentDocument')}
                                                        <Button
                                                            style={{ float: 'right' }}
                                                            size={'small'}
                                                            type={'primary'}
                                                            title={translate('buttons.download.container')}
                                                            onClick={() =>
                                                                this.props.downloadAttachmentAction(
                                                                    container.id,
                                                                    this.state.signatureParentDocumentId,
                                                                    selectedSignature.id
                                                                )
                                                            }
                                                            icon={'download'}
                                                        />
                                                    </span>
                                                }
                                                content={selectedSignature.commentDocumentTitle}
                                            />
                                        </Col>
                                        <Divider />
                                    </span>
                                )}
                                <Col span={24}>
                                    {this.getDropDownList(
                                        'signerName',
                                        translate('signatures.signerName'),
                                        selectedSignature.signerName,
                                        signerDNData
                                    )}
                                </Col>
                                <Divider />
                                <Col span={24}>
                                    {this.getDropDownList(
                                        'issuerName',
                                        translate('signatures.issuerName'),
                                        selectedSignature.issuerName,
                                        issuerDNData
                                    )}
                                </Col>
                                <Divider />
                                <Col span={24}>
                                    <DescriptionItem
                                        title={translate('signatures.type')}
                                        content={this.generateType(selectedSignature)}
                                    />
                                </Col>
                                <Divider />
                                {!selectedSignature.signatureTimestamp && (
                                    <Col span={24}>
                                        <DescriptionItem
                                            title={translate('signatures.creationDate')}
                                            content={formatDate(selectedSignature.creationDate, translate('language'))}
                                        />
                                    </Col>
                                )}
                                {!selectedSignature.signatureTimestamp && <Divider />}
                                <Col span={24}>
                                    <DescriptionItem
                                        title={translate('signatures.signatureKeyLength')}
                                        content={selectedSignature.signatureKeyLength}
                                    />
                                </Col>
                                <Divider />
                                <Col span={24}>
                                    <DescriptionItem
                                        title={translate('signatures.signatureAlgorithm')}
                                        content={selectedSignature.signatureAlgorithm}
                                    />
                                </Col>
                                <Divider />
                                <Col span={24}>
                                    <DescriptionItem
                                        title={translate('signatures.revocationInfo')}
                                        content={this.translateRevocationInfo(selectedSignature.revocationInfo)}
                                    />
                                </Col>
                                <Divider />
                                {selectedSignature.signatureTimestamp && (
                                    <Col span={24}>
                                        {this.getDropDownList(
                                            'signatureTimestamp',
                                            translate('signatures.signatureTimestamp'),
                                            formatDate(
                                                selectedSignature.signatureTimestamp.creationDate,
                                                translate('language')
                                            ),
                                            sigTsData
                                        )}
                                    </Col>
                                )}
                                {selectedSignature.signatureTimestamp && <Divider />}
                                {selectedSignature.lastArchiveTimestamp && (
                                    <Col span={24}>
                                        {this.getDropDownList(
                                            'lastArchiveTimestamp',
                                            translate('signatures.lastArchiveTimestamp'),
                                            formatDate(
                                                selectedSignature.lastArchiveTimestamp.creationDate,
                                                translate('language')
                                            ),
                                            lastArchTsData
                                        )}
                                    </Col>
                                )}
                                {selectedSignature.lastArchiveTimestamp && <Divider />}
                                {selectedSignature.eSzignoAttributeCertificates.length > 0 && (
                                    <Col span={24}>
                                        <DescriptionItem
                                            title={translate('modals.attributeCert.attributeCertificates')}
                                            content={
                                                <Button
                                                    size="small"
                                                    style={{ marginTop: '10px' }}
                                                    onClick={(e) => this.handleAttributeCertificatesModal(e)}>
                                                    <Icon type="paper-clip" />
                                                    {translate('modals.attributeCert.attributeCertificates')}
                                                </Button>
                                            }
                                        />
                                    </Col>
                                )}
                            </Row>
                            <div
                                style={{
                                    position: 'relative',
                                    bottom: 0,
                                    width: '100%',
                                    height: '110px',
                                    padding: '0px 16px',
                                    textAlign: 'center',
                                    left: 0,
                                    zIndex: 10,
                                    backgroundColor: '#fff'
                                }}>
                                <DeleteButton
                                    block={true}
                                    disabled={!selectedSignature.isDeletable}
                                    id={'deleteSignature'}
                                    onClick={() => {
                                        const successCallback = () => {
                                            updateContainers();
                                            updateContainerDataAction(container.id, currentTab === TAB_KEY_DEFAULT_CONTAINERS);
                                            this.onCloseSignatureDetails();
                                        };
                                        this.props.removeSignatureAction(
                                            selectedSignature,
                                            container.id,
                                            successCallback,
                                            setTableLoadingFalse,
                                            setTableLoadingTrue
                                        );
                                    }}>
                                    {translate('buttons.delete.signature')}
                                </DeleteButton>
                                <Button
                                    block={true}
                                    disabled={
                                        !this.isSignatureArchivable(selectedSignature) ||
                                        !signatureVerified ||
                                        !archiveButton
                                    }
                                    id={'archiveButton'}
                                    onClick={() => {
                                        this.props.disableArchiveButtonAction();
                                        this.archive(selectedSignature);
                                    }}
                                    style={{ marginTop: '8px' }}
                                    type={'primary'}>
                                    <Icon type="clock-circle-o" />
                                    {translate('buttons.archiveValidate.text')}
                                </Button>
                            </div>
                        </div>
                    )}
                </Drawer>
                {container.error !== undefined && (
                    <Alert
                        type="error"
                        showIcon
                        message={translate('messages.error.corruptedFile')}
                        style={{ marginBottom: 10 }}
                    />
                )}
                <ContainerDetails
                    container={container}
                    openSignatureDetails={this.onOpenSignatureDetails}
                    signatures={sigCounterSigList}
                />
            </div>
        );
    };

    documentUpload = async (event) => {
        const files = [...event.target.files];
        this.onDrop(files);
    };

    handleDocumentUpload = (files, docTitle) => {
        const { translate, uploadCompanyRegistrationDocument, uploadDocumentAction, container } = this.props;
        ReactGA.event({
            category: 'User',
            action: 'Document Upload',
        });
        this._isMounted && this.setState({ uploading: true });
        files.forEach((file) => {
            try {
                const formData = new FormData();
                if (getSchema(container.esNamespace) === 'Cégeljárás') {
                    formData.append('title', new Blob([docTitle], { type: 'text/plain;charset=utf8' }));
                }
                formData.append('file', file);
                if (getSchema(container.esNamespace) === 'Cégeljárás') {
                    uploadCompanyRegistrationDocument(container.id, formData)
                        .then(() => {
                            createNotificationShort(translate, {
                                description: translate('notifications.uploadOK.description', {
                                    fileName: file.name,
                                }),
                                message: translate('notifications.uploadOK.message'),
                                type: 'success',
                            })
                        })
                        .catch((e) => {
                            createNotification(translate, e.response.data.code, {
                                description: translate('notifications.uploadFailure.description', {
                                    fileName: file.name,
                                }),
                                message: translate('notifications.uploadFailure.message'),
                                type: 'error',
                            });
                        });
                } else {
                    uploadDocumentAction(container.id, formData)
                        .then((res) => {
                            createNotificationShort(translate, {
                                description: translate('notifications.uploadOK.description', {
                                    fileName: file.name,
                                }),
                                message: translate('notifications.uploadOK.message'),
                                type: 'success',
                            });

                            const originalFilename = file.name;

                            if (removeFilenameCounterPostfix(originalFilename) !== removeFilenameCounterPostfix(res.data.details.documents.slice(-1)[0].title)) {
                                notification.open({
                                    message: translate('notifications.uploadOK.fileNameChanged'),
                                    type: 'info',
                                    duration: 10
                                });
                            }
                        })
                        .catch((e) => {
                            createNotification(translate, e.response.data.code, {
                                description: translate(`errorCodes.${e.response.data.code}`),
                                message: translate('notifications.uploadFailure.message'),
                                type: 'error',
                            });
                        });
                }
            } catch (error) {
                if (error.message === 'USER_MAX_UPLOAD_SIZE_ERROR') {
                    createNotification(translate, error.message, {
                        description: translate(`errorCodes.${error.message}`),
                        message: translate('notifications.uploadFailure.message'),
                        type: 'error',
                    });
                }
            }
        });
    };

    setTitle = (newTitle) => {
        let file = this.state.files[0];
        file = new File([file], this.state.files[0].name, { type: file.type });
        this.handleDocumentUpload([file], newTitle);
    };

    onDrop = async (files) => {
        const { container, checkCompanyRegistrationDocumentAction, setTableLoadingTrue, setTableLoadingFalse } = this.props;

        if (isCegeljaras(container)) {
            setTableLoadingTrue();

            try {
                const documentFile = files[0];

                const formData = new FormData();
                formData.append('cegeljaras_document', documentFile);
                const response = await checkCompanyRegistrationDocumentAction(container.id, formData);

                if (!isEmpty(response.data)) {
                    // nem üres ha a dokumentum 'költség' vagy 'illeték' volt
                    createNotificationShort(this.props.translate, {
                        description: this.props.translate('notifications.uploadOK.description', {
                            fileName: documentFile.name,
                        }),
                        message: this.props.translate('notifications.uploadOK.message'),
                        type: 'success',
                    });
                    return;
                }
            } catch (e) {
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('notifications.uploadFailure.message'),
                    type: 'error',
                });
                return;
            } finally {
                setTableLoadingFalse();
            }

            this._isMounted && this.setState({ files: files });
            this.props.uploadCegeljarasDocument({ setTitle: this.setTitle, fileName: files[0].name });
        } else {
            this.handleDocumentUpload(files);
        }
    };

    isPDF(document) {
        return document.mimeType === 'application/pdf' || document.extension === 'pdf';
    }

    getPreviewURL(container, document) {
        const documentId = container.type === 'asic' ? document.title : document.id;
        return getApiUrl() + `/containers/${container.id}/documents/${documentId}/download`;
    }

    render() {
        const {
            translate,
            containerSignatures,
            documents,
            container,
            downloadDocumentAction,
            downloadAttachmentAction,
            signDocumentAction,
            setTableLoadingFalse,
            setTableLoadingTrue,
            isUploadDisabled,
        } = this.props;
        const containerType = container.type;
        const isContainerSigned = !isEmpty(containerSignatures);
        if (container.error !== undefined) {
            return <div>{this.header()}</div>;
        }
        if (containerType === 'pdf') {
            return (
                <div>
                    {this.header()}
                    <PdfPreviewWithHooks container={container} />
                </div>
            );
        } else {
            const asicDocHasSignature = (documentData) => {
                let hasSig = false;
                containerSignatures.forEach((element) => {
                    const hasSameSignatureId =
                        documentData.signatures.filter((docSignature) => docSignature.id === element.id).length > 0;
                    if (hasSameSignatureId) {
                        hasSig = true;
                    }
                });
                return hasSig;
            };
            const isRenameEnabled = (documentData) => {
                let documentHasSignature = documentData.signatures.length > 0;

                if (container.type === 'es3' || container.type === 'edossier') {
                    return !(getSchema(container.esNamespace) !== 'default' || isContainerSigned || documentHasSignature);
                }
                if (container.type === 'et3' || container.type === 'eacknowledgement') {
                    return false;
                }
                if (container.type === 'asic') {
                    return !asicDocHasSignature(documentData);
                }
                return true;
            };

            const isDeleteEnabled = (documentData) => {
                if (container.type === 'es3' || container.type === 'edossier') {
                    return !isContainerSigned;
                }
                if (container.type === 'et3' || container.type === 'eacknowledgement') {
                    return false;
                }
                if (container.type === 'asic') {
                    return !asicDocHasSignature(documentData);
                }
                return true;
            };

            const isSignDocumentEnabled = (documentData) => {
                return !isContainerInSigningWorkflow(container)
                    && container.type === 'edossier'
                    && isDeleteEnabled(documentData);
            }

            const columns = [
                {
                    dataIndex: 'title',
                    render: (title, record) => {
                        const documentTitleWithIcon = (
                            <div style={{ display: 'table' }}>
                                <Icon
                                    type={iconTypeFromType(record.extension)}
                                    style={{ fontSize: 24, marginRight: 8 }}
                                />
                                <div
                                    style={{
                                        display: 'table-cell',
                                        verticalAlign: 'middle',
                                        wordBreak: 'break-all',
                                        wordWrap: 'break-word',
                                        textAlign: 'left'
                                    }}
                                    title={record.title}
                                >
                                    {shorten(record.title, 95)}
                                </div>
                            </div>
                        );

                        if (!this.isPDF(record)) {
                            return documentTitleWithIcon;
                        }

                        return (
                            <InvisibleButton
                                onClick={() => {
                                    this.props.togglePDFPreviewDeprecated(record.previewUrl);
                                }}>
                                {documentTitleWithIcon}
                            </InvisibleButton>
                        );
                    },
                    title: translate('attributes.title')
                },
                {
                    align: 'center',
                    dataIndex: 'creationDate',
                    render: (date) => formatDate(date, translate('language')),
                    title:
                        containerType === 'asic'
                            ? translate('attributes.asicModificationDate')
                            : translate('attributes.creationDate'),
                    width: 176
                },
                {
                    align: 'center',
                    dataIndex: 'size',
                    render: (size) => formatBytes(size),
                    title: translate('attributes.size'),
                    width: 128
                },
                {
                    align: 'center',
                    dataIndex: 'document_signatures',
                    render: (text, currentDocument) => {
                        return currentDocument.signatures.map((signature, index) =>
                            container.type === 'asic' ? (
                                <Tooltip
                                    key={index}
                                    placement="right"
                                    title={translate('tooltip.asice', {
                                        container: container.fileBaseName
                                    })}>
                                    <Tag
                                        style={{
                                            marginBottom: 5,
                                            borderStyle: 'dashed',
                                            cursor: 'default',
                                            backgroundColor: 'transparent'
                                        }}>
                                        {signature.signerName}
                                    </Tag>
                                </Tooltip>
                            ) : (
                                <SignatureTag
                                    key = { index }
                                    signature = { signature }
                                    onOpenSignatureDetails = {
                                        () => { this.onOpenSignatureDetails(signature); }
                                    }
                                    downloadAttachment = {
                                        () => {
                                            if (container.type === 'asic') {
                                                downloadAttachmentAction(container.id, currentDocument.title, signature.id);
                                            } else {
                                                downloadAttachmentAction(container.id, currentDocument.id, signature.id);
                                            }
                                        }
                                    } />
                            )
                        );
                    },
                    title: translate('attributes.signatures'),
                    width: 200
                },
                {
                    align: 'center',
                    dataIndex: 'actions',
                    fixed: 'right',
                    render: (text, record, index) => {
                        const idToUse = container.type === 'asic' ? record.containerPath : record.id;
                        const documentMenu = (
                            <Menu>
                                <Menu.Item
                                    onClick={() => {
                                        downloadDocumentAction(container.id, idToUse, record.title);
                                    }}
                                    key="download">
                                    <Icon type="download" />
                                    {translate('buttons.download.document')}
                                </Menu.Item>
                                { isSignDocumentEnabled(record) &&
                                    <Menu.Item
                                        onClick={() => {
                                            signDocumentAction(idToUse);
                                        }}
                                        key="sign">
                                        <Icon type="form" />
                                        {translate('buttons.sign.text')}
                                        <SignHidden
                                            idPrefix={idToUse}
                                            container={container}
                                            documentId={idToUse}
                                            pdfFormSigning={false}
                                        />
                                    </Menu.Item>
                                }
                                { isRenameEnabled(record) &&
                                    <Menu.Item
                                        onClick={() => {
                                            let documentId = container.type === 'asic' ? record.title : record.id;
                                            this.props.renameDocumentModal(documentId, record.title, index);
                                        }}
                                        key="edit">
                                        <Icon type="edit" />
                                        {translate('buttons.rename.document')}
                                    </Menu.Item>
                                }
                                { isDeleteEnabled(record) &&
                                    <Menu.Item
                                        onClick={() =>
                                            this.props.deleteDocument(
                                                container.type,
                                                container.id,
                                                record,
                                                index,
                                                setTableLoadingFalse,
                                                setTableLoadingTrue
                                            )
                                        }
                                        key="delete">
                                        <Icon type="delete" />
                                        {translate('buttons.delete.document')}
                                    </Menu.Item>
                                }
                            </Menu>
                        );
                        return (
                            <Dropdown
                                overlay={documentMenu}
                                key={`menu_${index}`}
                                trigger={['click']}>
                                <Button title={translate('buttons.documentOptions')}>
                                    <Icon type="ellipsis" />
                                </Button>
                            </Dropdown>
                        );
                    },
                    title: translate('attributes.actions'),
                    width: 128
                }
            ];
            return (
                <Dropzone
                    disabled={isUploadDisabled}
                    noClick={true}
                    onDrop={this.onDrop}>
                    {({ isDragActive, getRootProps, getInputProps }) => {
                        return (
                            <section>
                                <div
                                    {...getRootProps()}
                                    style={{ outline: 'none' }}>
                                    <input {...getInputProps()} />
                                    <div style={isDragActive ? theme.dropZoneOverlayBoxStyle : { display: 'none' }}>
                                        <div>
                                            <Icon
                                                type="cloud-upload"
                                                theme="outlined"
                                                style={{ fontSize: '32px', marginRight: 10 }}
                                            />
                                        </div>
                                        {this.props.translate('notifications.uploadFiles')}
                                    </div>
                                    <div style={isDragActive ? theme.dropZoneStyle : {}}>
                                        {this.header()}
                                        <Table
                                            columns={columns}
                                            dataSource={documents.map((document) => ({
                                                creationDate: document.creationDate,
                                                extension: getExtension(document.title, document.mimeType),
                                                id: document.id,
                                                key: document.id ? document.id : document.containerPath,
                                                mimeType: document.mimeType,
                                                name: document.title,
                                                previewUrl: this.getPreviewURL(container, document),
                                                signatures: document.signatures,
                                                size: document.size,
                                                title: container.type === 'asic' ? document.containerPath : document.title,
                                                containerPath: document.containerPath
                                            }))}
                                            loading={this.state.tableLoading}
                                            locale={{
                                                emptyText: (
                                                    <div>
                                                        {container.type === 'edossier'
                                                            ? translate(
                                                                  'container.details.emptyDocumentList.text.edossier'
                                                              )
                                                            : translate(
                                                                  'container.details.emptyDocumentList.text.asic'
                                                              )}
                                                        <input
                                                            id={'upload-document-list'}
                                                            multiple={!isCegeljaras(container)}
                                                            onChange={this.documentUpload}
                                                            ref={(ref) => (this.uploadDocument = ref)}
                                                            style={{ display: 'none' }}
                                                            type={'file'}
                                                        />
                                                        <ActionLink
                                                            onClick={() => this.uploadDocument.click()}
                                                            disabled={isUploadDisabled}
                                                            color={ isUploadDisabled ? 'grey' : undefined }
                                                            >
                                                            {translate('container.details.emptyDocumentList.link')}
                                                        </ActionLink>
                                                    </div>
                                                )
                                            }}
                                            pagination={false}
                                            rowKey={(record) => {
                                                return record.key;
                                            }}
                                            scroll={{ x: 1000 }}
                                            style={{
                                                backgroundColor: theme.documentListBg,
                                                borderRadius: 4
                                            }}
                                        />
                                    </div>
                                </div>
                            </section>
                        );
                    }}
                </Dropzone>
            );
        }
    }
}

DocumentList.propTypes = {
    container: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        fileBaseName: PropTypes.string.isRequired,
        size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        creationDate: PropTypes.string.isRequired,
        esNamespace: PropTypes.string,
        ackVersion: PropTypes.string,
        pdfEmbeddedFiles: PropTypes.array,
        error: PropTypes.string,
        processStarter: PropTypes.string,
        workflowGuid: PropTypes.string,
        isCounterSignable: PropTypes.bool,
    }),
    isContainerSigned: PropTypes.bool.isRequired,
    isUploadDisabled: PropTypes.bool.isRequired,
    togglePDFPreviewDeprecated: PropTypes.func,
    translate: PropTypes.func,
    renameDocumentModal: PropTypes.func,
    deleteDocument: PropTypes.func,
    containerSignatures: PropTypes.array,
    documents: PropTypes.array,
    uploadCegeljarasDocument: PropTypes.func,
    uploadCompanyRegistrationDocument: PropTypes.func,
    uploadDocumentAction: PropTypes.func,
    disableArchiveButtonAction: PropTypes.func,
    removeSignatureAction: PropTypes.func,
    validateSignatureAction: PropTypes.func,
    enableArchiveButtonAction: PropTypes.func,
    archiveButton: PropTypes.bool,
    toggleTSA: PropTypes.func,
    createArchiveSignatureAction: PropTypes.func,
    openPdfEmbeddedFilesModal: PropTypes.func,
    tsaReq: PropTypes.any,
    tableLoading: PropTypes.bool,
    setTableLoadingFalse: PropTypes.func,
    setTableLoadingTrue: PropTypes.func,
    updateContainerDataAction: PropTypes.func,
    downloadDocumentAction: PropTypes.func,
    downloadAttachmentAction: PropTypes.func,
    signDocumentAction: PropTypes.func,
    openAttributeCertificatesModal: PropTypes.func,
    updateContainers: PropTypes.func,
    checkCompanyRegistrationDocumentAction: PropTypes.func,
    companyRegistrationModal: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.locale),
        tsaReq: state.tsa.requireTspCredentials,
        documents: state.documentList.documents,
        containerSignatures: state.containerList.signatures,
        archiveButton: state.enabled.archiveButton,
        currentTab: state.tabs.current,
        selectedSignature: state.containerList.selectedSignature
    };
};

function mapDispatchToProps(dispatch) {
    return {
        toggleTSA: () => {
            dispatch(toggleModal(TSA));
        },
        togglePDFPreviewDeprecated: (documentUrl) => {
            dispatch(toggleModal(PDF_PREVIEW_DEPRECATED, { documentUrl }));
        },
        uploadCegeljarasDocument: (file) => {
            dispatch(toggleModal(UPLOAD_CEGELJARAS, file));
        },
        renameDocumentModal: (documentId, documentTitle, index) => {
            // Index is needed to rename the document in view after a successful response.
            dispatch(toggleModal(RENAME_DOCUMENT, { documentId, documentTitle, index }));
        },
        deleteDocument: async (
            containerType,
            containerId,
            documentData,
            index,
            setTableLoadingFalse,
            setTableLoadingTrue
        ) => {
            dispatch(
                toggleModal(REMOVE_DOCUMENT, {
                    containerType: containerType,
                    containerId: containerId,
                    index: index,
                    documentData: documentData,
                    setTableLoadingFalse,
                    setTableLoadingTrue,
                })
            );
        },
        uploadDocumentAction: (id, document) => {
            return dispatch(uploadDocument(id, document));
        },
        uploadCompanyRegistrationDocument: (id, data) => {
            return dispatch(uploadCompanyRegistrationDocument(id, data));
        },
        validateSignatureAction: (containerId, signature) => {
            ReactGA.event({
                category: 'User',
                action: 'Signature Validation',
            });
            return dispatch(validateSignature(containerId, signature, null));
        },
        createArchiveSignatureAction: (containerId, signatureId) => {
            return dispatch(createArchiveSignature(containerId, signatureId));
        },
        removeSignatureAction: (
            signature,
            currentContainerId,
            successCallback,
            setTableLoadingFalse,
            setTableLoadingTrue
        ) => {
            dispatch(
                toggleModal(REMOVE_SIGNATURE, {
                    signature: signature,
                    contId: currentContainerId,
                    callback: () => {
                        successCallback();
                    },
                    setTableLoadingFalse,
                    setTableLoadingTrue,
                })
            );
        },
        disableArchiveButtonAction: () => {
            dispatch(disableArchiveButton());
        },
        enableArchiveButtonAction: () => {
            dispatch(enableArchiveButton());
        },
        openAttributeCertificatesModal: (eSzignoAttributeCertificates) => {
            dispatch(toggleModal(ATTRIBUTE_CERTIFICATES, eSzignoAttributeCertificates));
        },
        updateContainerDataAction: (id, noAction) => {
            dispatch(updateContainerData(id, noAction));
        },
        downloadDocumentAction: (contId, docId, fileName) => {
            dispatch(downloadDocument(contId, docId, fileName));
        },
        downloadAttachmentAction: (contId, docId, signatureId) => {
            dispatch(downloadCommentDocument(contId, docId, signatureId));
        },
        signDocumentAction: (documentId) => {
            authService.isRegistered()
                ? document.getElementById(documentId + '_signDocument').click()
                : dispatch(toggleModal(AUTHENTICATION));
        },
        updateContainers: () => {
            dispatch(containerActions.getContainers());
        },
        companyRegistrationModal: (file) => {
            dispatch(toggleModal(UPLOAD_CEGELJARAS, file));
        },
        checkCompanyRegistrationDocumentAction: (containerId, formData) => {
            return dispatch(checkCompanyRegistrationDocument(containerId, formData));
        },
        updateSelectedSignatureAction: (signature) => {
            return dispatch(updateSelectedSignature(signature));
        },
        resetSelectedSignatureAction: () => {
            return dispatch(resetSelectedSignature());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);
