import BackToHomePageButton from './buttons/BackToHomePageButton';
import React from 'react';
import MarkdownViewer from './MarkdownViewer';
import WebPage from './WebPage';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { getBaseUrl } from '../helper';

export default function Changelog() {
    const translate = useSelector((state) => getTranslate(state.locale));

    let url = getBaseUrl() + '/changelog/';
    if (translate('language') === 'hu-HU') {
        url += 'EN'
    } else {
        url += 'HU'
    }
    url += '.md?v=' + window.config.REACT_APP_BACKEND_VERSION

    return (
        <WebPage>
            <BackToHomePageButton />
            <MarkdownViewer url={url} />
        </WebPage>
    );
}
